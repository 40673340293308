import React from "react";

import DefaultLayout from "../../../../layouts/default";

const tacsTechnik = () => (
  <DefaultLayout title="Technik – WiCare|now tacs">
    <div className="white wicare">
      <div className="navContentSpacerHelper"></div>
      <header className="navWrapper">
        <nav className="mainNav">
          <div className="mainNavBackgroundHelper"></div>
          <div className="logo">
            <a href="/">
              <img
                src="/assets/images/logos/wigasoft-logo.svg"
                alt="WigaSoft Logo"
                width="175"
              />
            </a>
          </div>
          <div className="mainNav-opener"></div>
          <ul className="mainLinks">
            <li>
              <a href="/news/">News</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/heime/">Heime</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/spitaeler/">Spitäler</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/rehabilitation/">
                Rehabilitation
              </a>
            </li>
            <li>
              <a href="/portrait/">Über uns</a>
            </li>
            <li>
              <a href="/support/">Support</a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/wigasoft-ag">
                <div className="linkedInIcon" />
              </a>
            </li>
          </ul>
        </nav>

        <nav className="subNav2">
          <div className="subNav2Product">
            <div className="productImage">
              <img
                src="/assets/images/logos/WCN-tacs.svg"
                alt="WiCare tacs Logo"
                width="40"
              />
            </div>
            <div className="productText">
              <span className="line">WiCare|tacs</span>
              <span className="line">&nbsp;das Controlling im Griff</span>
            </div>
          </div>
          <div className="subNav2-opener"></div>
          <ul className="subNav2Links">
            <li>
              <a href="/dokumentationsloesungen/wicare-tacs/">Übersicht</a>
            </li>
            <li className="is-active">
              <a href="/dokumentationsloesungen/wicare-tacs/technik/">
                Technik
              </a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/wicare-tacs/referenzen/">
                Referenzen
              </a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/wicare-tacs/downloads/">
                Downloads
              </a>
            </li>
          </ul>
        </nav>
      </header>

      <div className="menuBackground">
        <div className="wrapper">
          <section className="has-padding-top has-padding-bottom">
            <h2>IT Anforderung</h2>
            <p className="fliesstext3 smallStaticPadding-bottom">
              Die Anforderungen beschreiben die minimalen Voraussetzungen an die
              IT-Infrastruktur, damit ein sicherer und performanter Betrieb der
              WiCare|Suite sichergestellt werden kann.
            </p>
            <div className="tabContainerTechnik">
              {/*
          <!-- Tab 1 -->*/}
              <input
                type="radio"
                name="tabset"
                id="tab1"
                aria-controls="tab1"
                checked="checked"
              />
              <label for="tab1" className="tab">
                <h5>PC / Notebook</h5>
              </label>
              {/*
          <!-- Tab 2 -->*/}
              <input
                type="radio"
                name="tabset"
                id="tab2"
                aria-controls="tab2"
              />
              <label for="tab2" className="tab">
                <h5>Datenbank- / Applikationsserver</h5>
              </label>
              {/*
          <!-- Tab 3 -->*/}
              <input
                type="radio"
                name="tabset"
                id="tab3"
                aria-controls="tab3"
              />
              <label for="tab3" className="tab">
                <h5>Tablet</h5>
              </label>
              {/*
          <!-- Tab 4 -->*/}
              {/* <!-- <input type="radio" name="tabset" id="tab4" aria-controls="tab4" />
          <label for="tab4" className="tab">
            <h5>Smartphone</h5>
          </label> --> */}

              <div className="tabPanels">
                <section id="tab1" className="tabPanel">
                  <p className="fliesstext medium">Betriebssystem</p>
                  <p className="fliesstext">
                    Windows (Version 10, 11), 64bit-Architektur
                  </p>
                  <br />
                  <p className="fliesstext medium">Arbeitsspeicher</p>
                  <p className="fliesstext">minimal 4 GB</p>
                  <br />
                  <p className="fliesstext medium">Prozessor</p>
                  <p className="fliesstext">Intel i5 / i7, DualCore</p>
                  <br />
                  <p className="fliesstext medium">
                    Freier Festplattenspeicher
                  </p>
                  <p className="fliesstext">2 GB</p>
                  <br />
                  <p className="fliesstext medium">Bildschirmauflösung</p>
                  <p className="fliesstext">1280 x 1024</p>
                  <br />
                  <p className="fliesstext medium">Netzwerk</p>
                  <p className="fliesstext">100 Mbit Ethernet / WLAN</p>
                  <p className="fliesstext5">
                    Bei Einsatz von Terminal-Server sind die Anforderungen
                    abhängig von der Anzahl User und weiteren Applikationen.
                  </p>
                </section>
                <section id="tab2" className="tabPanel">
                  <p className="fliesstext medium">Betriebssystem</p>
                  <p className="fliesstext">
                    Windows Server (Version 2016, 2019, 2022, 2025),
                    64bit-Architektur
                  </p>
                  <br />
                  <p className="fliesstext medium">Arbeitsspeicher</p>
                  <p className="fliesstext">minimal 8 GB</p>
                  <br />
                  <p className="fliesstext medium">Prozessor</p>
                  <p className="fliesstext">Intel Xeon, ab 2.4 GHz, 4 Kerne</p>
                  <br />
                  <p className="fliesstext medium">Festplattenspeicher</p>
                  <p className="fliesstext">
                    System-Laufwerk C:
                    <br />
                    gemäss Anforderungen Betriebssystem
                    <br />
                    <br />
                    Speicherbedarf Applikationsserver:
                    <br />
                    4 GB pro Umgebung
                    <br />
                    <br />
                    Speicherbedarf Datenbank WiCare|Suite: abhängig von
                    Betriebsgrösse und Einsatzumfeld, minimal 20 GB pro Umgebung
                    <br />
                    <br />
                    Speicherbedarf Transaktionsprotokoll und Backup: abhängig
                    von Datenbankgrösse und Backup-Konzept, minimal 60 GB pro
                    Produktiv-Umgebung, 20 GB pro nicht-produktiver Umgebung
                  </p>
                  <br />
                  <p className="fliesstext medium">Bildschirmauflösung</p>
                  <p className="fliesstext">1280 x 1024</p>
                  <br />
                  <p className="fliesstext medium">Netzwerk</p>
                  <p className="fliesstext">100 Mbit Ethernet</p>
                  <br />
                  <p className="fliesstext medium">Datenbank-Server</p>
                  <p className="fliesstext">
                    Microsoft SQL Server (Version 2016 SP2, 2017, 2019, 2022),
                    Standard Edition
                  </p>
                  <br />
                  <p className="fliesstext medium">Reporting Tool</p>
                  <p className="fliesstext">
                    Microsoft SQL Server Reporting Services (Version 2016 SP2,
                    2017, 2019, 2022)
                  </p>
                  <br />
                  <p className="fliesstext medium">Datenbankverwaltung</p>
                  <p className="fliesstext">
                    Microsoft SQL Server Management Studio
                  </p>
                  <br />
                  <p className="fliesstext medium">Remote-Zugang</p>
                  <p className="fliesstext">
                    Microsoft Remotedesktop oder TeamViewer
                    <br />
                    <br />
                  </p>
                  <p className="fliesstext5">
                    Die Angaben von Datenbank- und File-Server sind bis max. 20
                    Clients, ohne Drittsoftware, ausgelegt. Bei weiteren Clients
                    bzw. Software steigen die Anforderungen entsprechend. Für
                    den Datenbank-Server wird auch der kostenlose Microsoft SQL
                    Server 2016 Express Edition unterstützt. Auf Grund der
                    Limitationen (max. 1 GB RAM, 10 GB Datenbankgrösse) eignet
                    sich diese Edition aber nur für Betriebe mit sehr geringer
                    Benutzerzahl oder für Test-/Demo-Umgebungen. Bei steigender
                    Datenmenge ist zwingend ein Wechsel auf Standard Edition
                    vorzunehmen. Bitte kontaktieren Sie uns, falls Sie Fragen
                    haben. Besten Dank.
                  </p>
                </section>
                <section id="tab3" className="tabPanel">
                  <p className="fliesstext">
                    Für WiCare|now Komponenten gemäss Spezifikation.
                    <br />
                    <br />
                  </p>
                  <p className="fliesstext medium">Betriebssystem</p>
                  <p className="fliesstext">Android, iOS, Windows 10</p>
                  <br />
                  <p className="fliesstext medium">Bildschirmauflösung</p>
                  <p className="fliesstext">ab 1024 x 768</p>
                  <br />
                  <p className="fliesstext5 medium is-color-orange padding-17-top">
                    WiCare|now benötigt eine aktive WLAN-Verbindung.
                  </p>
                </section>
                {/* <!-- <section id="tab4" className="tabPanel">
              <p className="fliesstext medium">Betriebssystem</p>
              <p className="fliesstext">Android, iOS</p>
              <br />
              <p className="fliesstext medium">Bildschirmauflösung</p>
              <p className="fliesstext">ab 375 x 667</p>
              <br />
              <p className="fliesstext5 medium is-color-orange padding-17-top">
                WiCare|now benötigt eine aktive WLAN-Verbindung.
              </p>
            </section> --> */}
              </div>
            </div>
          </section>
        </div>

            <section className="newsletterAnmeldung">
      <div className="wrapper nlPadding-top">
        <a className="btn grey" href="/newsletter">Newsletteranmeldung</a><br /><br /><br />
      </div>
    </section>

        <footer className="mainFooter">
          <div className="wrapper">
            <div className="breadcrumbs has-padding-small-top smallStaticPadding-bottom">
              <ul>
                <li>
                  <a href="/">Startseite</a>
                </li>
                <li>
                  <a href="/dokumentationsloesungen/wicare-tacs/">
                    WiCare|tacs<sup>&#174;</sup>
                  </a>
                </li>
                <li>
                  <a href="/dokumentationsloesungen/wicare-tacs/technik/">
                    Technik
                  </a>
                </li>
              </ul>
            </div>
            <div className="footerNotAnotherFlexboxButAGrid linklist">
              <div className="flex-item1">
                <ul>
                  <li>
                    <a href="/dokumentationsloesungen/heime/">
                      <strong>Heime</strong>
                    </a>
                  </li>
                  <li>
                    <a href="/dokumentationsloesungen/spitaeler/">
                      <strong>Spitäler</strong>
                    </a>
                  </li>
                  <li>
                    <a href="/dokumentationsloesungen/rehabilitation/">
                      <strong>Rehabilitation</strong>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex-item2">
                <ul>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-l/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-L
                      BESA
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-l/index_rai">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-L
                      RAI-NH
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-b/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-B
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-h/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-H
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-r/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-R
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex-item3">
                <ul>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-tacs/">
                      WiCare|now tacs<sup>&#174;</sup>
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-lep/">
                      WiCare|now LEP<sup>&#174;</sup>
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-le/">
                      WiCare|now LE
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex-item4">
                <ul>
                  <li>
                    <a href="/portrait/">
                      <strong>Über uns</strong>
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/portrait/lehrlinge/">Lernende</a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/portrait/partner/">Partner</a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/portrait/mitgliedschaften/">Mitgliedschaften</a>
                  </li>
                  <li>
                    <a href="/portrait/kontakt/">Kontakt und Anfahrt</a>
                  </li>
                </ul>
              </div>
              <div className="flex-item5">
                <ul>
                  <li>
                    <a href="/support/">
                      <strong>Support</strong>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row copyright">
            <div className="wrapper">
              <div className="column">
                © 2025 WigaSoft. Alle Rechte vorbehalten.&nbsp;
              </div>
              <div className="column has-padding-left">
                <ul>
                  <li>
                    <a href="/datenschutz/">Datenschutzerklärung</a>
                  </li>
              <li>
                <a href="/impressum/">Impressum</a>
              </li>
                </ul>
              </div>
              <div className="column align-right">
                <a href="/portrait/kontakt/" className="anfahrt">
                  <img
                    id="location-pointer"
                    src="/assets/images/icons/icon-anfahrt.svg"
                  />
                </a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </DefaultLayout>
);

export default tacsTechnik;
